<template>
  <v-form>
    <v-container fill-height>
      <v-row>
        <v-col md="6">
          <v-text-field v-model="profile.id" label="Id" readonly></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="profile.username"
            label="Username"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="profile.firma"
            label="Firma"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="profile.salutation"
            label="Anrede"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="profile.firstName"
            label="Vorname"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="profile.lastName"
            label="Nachname"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="profile.street_and_house"
            label="Strasse"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="profile.zip"
            label="PLZ"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="profile.city"
            label="Stadt"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="profile.state"
            label="Bundesland"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="profile.country"
            label="Land"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="profile.email"
            label="Email"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="profile.roles"
            label="Roles"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
// TODO: group UI items into UI-Group components. E.g. profile data -> "profile" , address data -> "address"

export default {
  data: () => ({
    profile: {
      id: "",
      username: "",
      email: "",
      roles: []
    }
  }),
  async mounted() {
    this.$store.commit("setCurrentDealTabs", []);
    this.$store.commit("setMainContextName", "Account");
    this.profile = this.$store.getters.getUserObject;
  }
};
</script>

<style></style>
